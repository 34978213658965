import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import listFill from '@iconify/icons-eva/list-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import {
  Box,
  List,
  Link,
  Stack,
  Drawer,
  Button,
  Divider,
  ListItem,
  Typography,
  IconButton,
  ListItemText,
  ListItemIcon,
  Avatar
} from '@material-ui/core';
//
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import { MIconButton } from '../@material-extend';
import LOGO from '../../images/skyniche-logo.png'
import ChristmasLogo from '../../images/skyniche-christmas.gif'

// ----------------------------------------------------------------------

const ICON_SIZE = 22;
const PADDING = 2.5;
const DRAWER_WIDTH = 330;

// ----------------------------------------------------------------------

ParentItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  hasSub: PropTypes.bool
};

function ParentItem({ icon, title, hasSub, ...other }) {
  return (
    <ListItem button sx={{ textTransform: 'capitalize', height: 50,mt:title === 'News' && 3 }} {...other}>
      <ListItemText primaryTypographyProps={{ typography: 'body2' }}>
        <Link underline='none' sx={{
          color: '#000048',
          fontSize: '20px',
          fontWeight: '450',
          fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        }}>
          {title}
        </Link>
      </ListItemText>
      {hasSub && title !== 'Global Clientele' && (<Stack
        className='bx--right-arrow'
        sx={{ fontSize: '1.5rem', color: '#6092cd' }}
      />)}
    </ListItem>
  );
}

SubMenu.propTypes = {
  parent: PropTypes.object,
  pathname: PropTypes.string,
  handleNavigation: PropTypes.func
};

function SubMenu({ parent, pathname, handleNavigation }) {
  const [open, setOpen] = useState(false);
  const { title, icon, path, children,country_src } = parent;

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (children) {
    return (
      <>
        <ParentItem title={title} icon={icon} onClick={handleOpen} hasSub />

        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: DRAWER_WIDTH - 12 } }}
        >
          <Stack direction="row" onClick={handleClose} alignItems="center" px={1} py={1.5}>
            <IconButton>
              <Stack
                className='bx--left-arrow'
                sx={{ fontSize: '1.3rem', color: '#6092cd', mt: 0.3 }}
              />
            </IconButton>
            <ListItemText primaryTypographyProps={{ typography: 'body2' }}>
              <Link sx={{
                color: '#000048',
                fontSize: '20px',
                fontWeight: '450',
                fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
              }} underline='none'>
                {title}
              </Link>
            </ListItemText>
          </Stack>
          <Divider />

          <Scrollbar>
            <Stack spacing={5} py={3}>
              {children.map((list, index) => (
                <List key={index} disablePadding>
                  {list.items.map((link) => (
                    <ListItem
                      button
                      disableGutters
                      key={link.title}
                      onClick={() => handleNavigation(link, title)}
                      sx={{ px: 1.5 }}
                    >
                      {title !== 'Global Clientele' ?(<ListItemIcon
                        sx={{
                          mr: 0.5,
                          width: ICON_SIZE,
                          height: ICON_SIZE,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box sx={{ width: 4, height: 4, bgcolor: 'currentColor', borderRadius: '50%' }} />
                      </ListItemIcon>) : <Avatar sx={{
                          mr: 1.5,
                          width: 30,
                          height: 30,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border:'2px solid #e6e6e6',
                        }} src={link.country_src} alt={link.title} />}
                      <ListItemText primaryTypographyProps={{ typography: 'body2' }}>
                        <Link underline='none' sx={{
                          color: '#000048',
                          fontSize: '19px',
                          fontWeight: '450',
                          fontFamily: '"Gellix",system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                        }}>
                          {link.title}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              ))}
            </Stack>
          </Scrollbar>
        </Drawer>
      </>
    );
  }

  return <ParentItem component={RouterLink} title={title} icon={icon} to={path} />;
}

MegaMenuMobile.propTypes = {
  navConfig1: PropTypes.array,
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool
};

export default function MegaMenuMobile({ navConfig1, isOffset, isHome }) {
  const { pathname } = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (openDrawer) {
      handleDrawerClose();
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleNavigation = (item, title) => {
    if (title === 'Services') {
      navigate(`/services/${item.title1}`, { state: item });
    } else if (title === 'Industries') {
      navigate(`/industry/${item.title1}`, { state: item });
    } else if (title === 'Solutions') {
      navigate(`/solutions/${item.title1}`, { state: item });
    }else {
      title !== 'Global Clientele' && navigate(item.path);
    }
  };

  return (
    <>
      <MIconButton
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isHome && { color: 'text.primary' }),
          ...(isOffset && { color: 'text.primary' })
        }}
        aria-label="Open drawer menu" // Adding accessible name
      >
        <Icon style={{ width: 35, height: 35 }} icon={menu2Fill} />
      </MIconButton>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: DRAWER_WIDTH, } }}
      >
        <Scrollbar>
          <Link underline='none' component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <img style={{ width: '220px', height: '55px', marginTop: '18px', marginBottom: '16px', marginLeft: '20px' }} src={LOGO} alt='Logo' />
            {/* <img style={{ width: '225px', height: '70px', marginTop: '14px', marginBottom: '16px', marginLeft: '20px' }} src={ChristmasLogo} alt='Logo' /> */}
          </Link>

          {navConfig1.map((parent) => (
            <SubMenu key={parent.title} parent={parent} pathname={pathname}  handleNavigation={handleNavigation} />
          ))}
        </Scrollbar>
      </Drawer>
    </>
  );
}
